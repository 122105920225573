import React, { memo, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { TweenMax } from 'gsap/all';

import './CollapsibleItem.scss';

import { sanitizer, customPropTypes, ease, useTransitionDelay } from '../../util';
import { STAGGER } from '../../util/motion-manager/constant';

function CollapsibleItem({ data: { title, description }, onToggle, isOpen, motionState, index, ...options }) {
  const collapsibleRef = useRef(null);
  const containerRef = useRef(null);

  const additionalClassNames = {
    'is-open': isOpen,
    'is-hidden': options.isHidden,
    'is-large': options.isLarge,
    'with-border': options.withBorder,
  };

  const transitionDelay = useTransitionDelay(STAGGER * (index + 1));

  const handleToggle = useCallback(() => onToggle(index), [index, onToggle]);

  useEffect(() => {
    if (isOpen) {
      TweenMax.to(collapsibleRef.current, 0.33, { opacity: 1, ease: ease.ease14 });
      TweenMax.set(collapsibleRef.current, { height: 'auto' });
      TweenMax.from(collapsibleRef.current, 0.4, { height: 0 });
    } else {
      TweenMax.to(collapsibleRef.current, 0.33, { opacity: 0, ease: ease.ease14 });
      TweenMax.to(collapsibleRef.current, 0.4, { height: 0 });
    }
  }, [isOpen]);

  return (
    <div
      ref={containerRef}
      className={classnames('CollapsibleItem', additionalClassNames, motionState)}
      role="button"
      aria-pressed={isOpen ? 'true' : 'false'}
      aria-expanded={isOpen ? 'true' : 'false'}
      aria-controls={`faq-${index + 1}`}
      title={title}
    >
      <div className="clickable-section" onClick={handleToggle}>
        <div className="collapsible-icon" style={transitionDelay}>
          <div className="bar" />
          <div className="bar" />
        </div>
        <h5 className="title" style={transitionDelay}>
          {title}
        </h5>
      </div>
      <div
        id={`faq-${index + 1}`}
        className="blog-style collapsible-section"
        ref={collapsibleRef}
        dangerouslySetInnerHTML={{ __html: sanitizer(description, true, {}) }}
      />
    </div>
  );
}

CollapsibleItem.propTypes = checkExtraProps({
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool,
  isHidden: PropTypes.bool,
  isLarge: PropTypes.bool,
  withBorder: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  motionState: customPropTypes.motionStatePropTypes,
  index: PropTypes.number.isRequired,
});

CollapsibleItem.defaultProps = {};

export default memo(CollapsibleItem);
