import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';

import './SideNav.scss';

import { hashManager } from '../../util';

function SideNav({ links, activeIndex }) {
  return (
    <aside className="SideNav">
      <ul>
        {links.map(({ stringIndex, title, id }) => {
          return (
            <li key={id} className={classnames({ active: links[activeIndex].id === id })}>
              <a href={`#${id}`} onClick={(e) => hashManager.changeByClick(e, id)}>
                {stringIndex} {title}
              </a>
            </li>
          );
        })}
      </ul>
    </aside>
  );
}

SideNav.propTypes = checkExtraProps({
  links: PropTypes.arrayOf(
    PropTypes.shape({
      stringIndex: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  activeIndex: PropTypes.number.isRequired,
});

SideNav.defaultProps = {};

export default memo(SideNav);
