import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { CTAInternalLink } from '../../CTA';

function cta({ data: { text, link } }) {
  return <CTAInternalLink link={link} text={text} />;
}

cta.propTypes = checkExtraProps({
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        html: PropTypes.string,
      }),
    ),
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    html: PropTypes.string,
  }).isRequired,
});

export default memo(cta);
