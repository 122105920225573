import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import ChapterSection from './ChapterSection';
import SideNav from '../SideNav/SideNav';
import WhitepaperWrapper from '../WhitepaperWrapper/WhitepaperWrapper';
import { useSectionTimeSpent } from '../../util';
// import useChapterScroll from './use-chapter-scroll';

import './Chapter.scss';

function Chapter({
  data: { stringIndex, sectionIndexText, minutesToRead, title, sections = [], id },
  name,
  links,
  activeIndex,
}) {
  const titleRef = useRef(null);
  const chapterRef = useRef(null);
  const whiteBackgroundRef = useRef(null);
  // useChapterScroll(chapterRef, titleRef, whiteBackgroundRef);
  useSectionTimeSpent(name, chapterRef);

  return (
    <section className="Chapter" id={id} ref={chapterRef} data-title={`${stringIndex} ${title}`}>
      <div className="background-layer" />
      <div className="title-container" ref={titleRef}>
        <p className="minutes">
          {sectionIndexText} <span>{minutesToRead}</span>
        </p>
        <h1>{title}</h1>
      </div>
      <WhitepaperWrapper whiteRef={whiteBackgroundRef}>
        {links && <SideNav links={links} activeIndex={activeIndex} />}

        <article className="sections-wrapper">
          {sections?.map((data, index) => (
            <ChapterSection key={index} data={data} />
          ))}
        </article>
      </WhitepaperWrapper>
    </section>
  );
}

Chapter.propTypes = checkExtraProps({
  data: PropTypes.shape({
    stringIndex: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    sectionIndexText: PropTypes.string,
    minutesToRead: PropTypes.string,
    sections: PropTypes.arrayOf(ChapterSection.type.propTypes.data).isRequired,
  }).isRequired,
  activeIndex: PropTypes.number,
  name: PropTypes.string.isRequired,
  links: SideNav.type.propTypes.links,
});

Chapter.defaultProps = {};

export default memo(Chapter);
