import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';

import './CollapsibleGrid.scss';

import CollapsibleItem from '../CollapsibleItem/CollapsibleItem';
import { ReactComponent as DownArrowSvg } from '../../assets/svgs/down-arrow.svg';

import { customPropTypes, useTransitionDelay, track } from '../../util';
import { STAGGER } from '../../util/motion-manager/constant';

function CollapsibleGrid({ items, itemsToShow, showMoreText, theme, motionState, name }) {
  const [openItemId, setOpenItemId] = useState(null);
  const [showAllItems, setShowAllItems] = useState(false);
  const isFAQTheme = theme === 'faq';

  const buttonTransitionDelay = useTransitionDelay(itemsToShow * STAGGER);

  const handleToggle = useCallback(
    (index) => {
      if (index === openItemId) {
        // close
        setOpenItemId(null);
        track(name, { name: `${name}_${index + 1}_Collapse` });
      } else {
        // open
        setOpenItemId(index);
        track(name, { name: `${name}_${index + 1}_Expand` });
      }
    },
    [openItemId, name],
  );

  const showAll = useCallback(() => {
    setShowAllItems(true);
    track(name, { name: 'SeeAll' });
  }, [name]);

  return (
    <div className={classnames('CollapsibleGrid', `theme-${theme}`, motionState)}>
      <div className="grid-container">
        {items &&
          items.map((item, index) => {
            return (
              <CollapsibleItem
                key={index}
                data={item}
                onToggle={handleToggle}
                isOpen={openItemId === index}
                isHidden={index >= itemsToShow && !showAllItems}
                isLarge={isFAQTheme}
                withBorder={isFAQTheme}
                motionState={motionState}
                index={index}
              />
            );
          })}
      </div>
      {items?.length > itemsToShow && (
        <button
          className={classnames('toggle-button', {
            hide: showAllItems,
          })}
          onClick={showAll}
          style={buttonTransitionDelay}
        >
          <span className="toggle-button-text">{showMoreText}</span> <DownArrowSvg className="down-arrow-svg" />
        </button>
      )}
    </div>
  );
}

CollapsibleGrid.propTypes = checkExtraProps({
  items: PropTypes.arrayOf(CollapsibleItem.type.propTypes.data).isRequired,
  theme: PropTypes.oneOf(['lexicon', 'faq']).isRequired,
  itemsToShow: PropTypes.number,
  showMoreText: PropTypes.string.isRequired,
  motionState: customPropTypes.motionStatePropTypes.isRequired,
  name: PropTypes.string.isRequired,
});

CollapsibleGrid.defaultProps = {
  itemsToShow: 4,
};

export default memo(CollapsibleGrid);
