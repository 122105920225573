import React, { useEffect, useRef } from 'react';

import './WhitePaperPage.scss';

import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { graphql } from 'gatsby';
import Splash from '../Splash/Splash';
import Lexicon from '../Lexicon/Lexicon';
import DownloadPDF from '../DownloadPDF/DownloadPDF';
import MobileNav from '../MobileNav/MobileNav';

import Chapter from '../Chapter/Chapter';
import WhitepaperWrapper from '../WhitepaperWrapper/WhitepaperWrapper';

import { hashManager } from '../../util';

function WhitePaperPage({ data }) {
  const { chapters, splash, lexicon, downloadPdfUrl } = data;
  const sideNavData = chapters.map(({ stringIndex, title, id }) => ({ stringIndex, title, id }));

  const downloadRefFromParent = useRef(null);

  useEffect(() => {
    hashManager.checkHashOnLoad();
  }, []);

  return (
    <>
      {sideNavData && <MobileNav links={sideNavData} />}

      <Splash data={splash} downloadRef={downloadRefFromParent} />

      {chapters?.map((data, index) => (
        <Chapter
          key={data.title}
          data={data}
          links={sideNavData}
          name={`WhitePaper_Chapter${index + 1}`}
          activeIndex={sideNavData && index}
        />
      ))}

      <WhitepaperWrapper smallMargin>
        <Lexicon data={lexicon} />
      </WhitepaperWrapper>

      <DownloadPDF data={{ url: downloadPdfUrl, parentRef: downloadRefFromParent }} />
    </>
  );
}

WhitePaperPage.propTypes = checkExtraProps({
  data: PropTypes.shape({
    chapters: PropTypes.arrayOf(Chapter.type.propTypes.data),
    splash: Splash.type.propTypes.data,
    lexicon: Lexicon.type.propTypes.data,
    downloadPdfUrl: PropTypes.string,
  }).isRequired,
});

export default WhitePaperPage;

export const fragments = graphql`
  fragment WhitepaperPage on WpPage_Flexiblelayout_FlexibleChildren_WhitepaperPage {
    chapters {
      id
      minutesToRead
      sectionIndexText
      sections {
        id
        items {
          text
          html
          items {
            html
          }
          link
          type
        }
        title
      }
      stringIndex
      title
    }
    downloadPdfUrl
    lexicon {
      description
      id
      items {
        description
        title
      }
      jumpToLinkTitle
      name
      note
      showMoreText
      title
    }
    splash {
      backgroundText
      id
      jumpToLinkTitle
      name
      pageName
      scrollToRead
      welcome
    }
  }

  fragment WhitepaperPage_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_WhitepaperPage {
    chapters {
      id
      minutesToRead
      sectionIndexText
      sections {
        id
        items {
          text
          html
          items {
            html
          }
          link
          type
        }
        title
      }
      stringIndex
      title
    }
    downloadPdfUrl
    lexicon {
      description
      id
      items {
        description
        title
      }
      jumpToLinkTitle
      name
      note
      showMoreText
      title
    }
    splash {
      backgroundText
      id
      jumpToLinkTitle
      name
      pageName
      scrollToRead
      welcome
    }
  }
`;
