import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import * as components from './components';

function ChapterSection({ data: { title, items = [], id }, ...props }) {
  return (
    <section id={id} className="chapter-section">
      <h4>{title}</h4>

      {items?.map((item, index) => {
        const { type } = item;
        if (!type) return;
        const Component = components[type];
        return <Component key={index} data={item} />;
      })}
    </section>
  );
}

ChapterSection.propTypes = checkExtraProps({
  data: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.array,
  }).isRequired,
});

ChapterSection.defaultProps = {};

export default memo(ChapterSection);
