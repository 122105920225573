import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';

import './Splash.scss';

import Logo from '../Logo/Logo';

import { customPropTypes, useSectionTimeSpent } from '../../util';

function Splash({ data: { backgroundText, welcome, pageName, scrollToRead, name, id } }) {
  const containerRef = useRef(null);

  const purplePage = useRef(null);
  const greenPage = useRef(null);
  const whitePage = useRef(null);

  const welcomeTextRef = useRef(null);
  const textContainerRef = useRef(null);
  const scrollIndicatorRef = useRef(null);
  const scrollTextRef = useRef(null);
  const progressLineRef = useRef(null);

  // const isAnimateInDone = useRef(false);

  // const windowSize = useWindowSize();
  // const isLarge = windowSize.innerWidth >= BREAKPOINTS.large;

  // const [timelineIn, setTimelineIn] = useState(null);
  // const [timelineScroll, setTimelineScroll] = useState(null);

  // const animateInit = useCallback(() => {
  //   TweenMax.set(containerRef.current, { autoAlpha: 1 });
  //   TweenMax.set(welcomeTextRef.current, { opacity: 0, x: -20, skewX: '10deg' });

  //   TweenMax.set(whitePage.current, { autoAlpha: 0, x: 90, y: -222, scale: 0.7 });
  //   TweenMax.set(greenPage.current, { autoAlpha: 0, x: 8, y: -335, scale: 0.6 });
  //   TweenMax.set(purplePage.current, { autoAlpha: 0, x: 167, y: -330, scale: 0.6 });

  //   TweenMax.set(textContainerRef.current.childNodes, { autoAlpha: 0, y: -20 });

  //   TweenMax.set(scrollIndicatorRef.current, { autoAlpha: 0 });
  //   TweenMax.set(scrollTextRef.current, { autoAlpha: 0, y: -20 });
  //   TweenMax.set(downloadRef.current, { y: 125 });
  // }, [downloadRef]);

  // const animateDownloadPDF = useCallback(() => {
  //   TweenMax.to(downloadRef.current, 0.2, { y: 0, ease: ease.ease12 });
  // }, [downloadRef]);

  // const animateIn = useCallback(() => {
  //   // NOTE: If it has a hash we need to skip both timelines
  //   if (getDangerousUnsanitizedHash()) {
  //     timelineIn.seek('end');
  //     if (isLarge) timelineScroll.seek('end');
  //   } else {
  //     timelineIn.play();
  //     if (!isLarge) animateDownloadPDF();
  //   }
  // }, [animateDownloadPDF, isLarge, timelineIn, timelineScroll]);

  // const buildInTimeline = useCallback(() => {
  //   return new TimelineMax({ paused: true, onComplete: () => (isAnimateInDone.current = true) })
  //     .to(welcomeTextRef.current, 0.16, { opacity: 0.08, delay: 0.33, ease: ease.ease10 }, 0)
  //     .to(welcomeTextRef.current, 0.66, { x: 0, skewX: '0deg', delay: 0.33, ease: ease.ease10 }, 0)
  //     .to(whitePage.current, 0.16, { autoAlpha: 1 }, 0)
  //     .to(whitePage.current, 1, { x: 0, y: 0, scale: 1, ease: ease.ease9 }, 0)
  //     .to(greenPage.current, 1, { x: 0, y: 0, scale: 1, ease: ease.ease9 }, 0)
  //     .to(purplePage.current, 1, { x: 0, y: 0, scale: 1, ease: ease.ease9 }, 0)
  //     .to(greenPage.current, 0.16, { autoAlpha: 1 }, 0.16)
  //     .to(purplePage.current, 0.16, { autoAlpha: 1 }, 0.16)
  //     .staggerTo(textContainerRef.current.childNodes, 0.83, { y: 0, ease: ease.ease10 }, 0.06, 0.43)
  //     .staggerTo(textContainerRef.current.childNodes, 0.33, { autoAlpha: 1, ease: ease.ease11 }, 0.06, 0.6)
  //     .to(scrollIndicatorRef.current, 0.33, { autoAlpha: 1 }, 0.66)
  //     .to(scrollTextRef.current, 0.83, { y: 0, ease: ease.ease10 }, 0.66)
  //     .to(scrollTextRef.current, 0.33, { autoAlpha: 1, ease: ease.ease11 }, 0.83)
  //     .addLabel('end');
  // }, []);

  // const buildScrollTimeline = useCallback(() => {
  //   return new TimelineMax({ paused: true })
  //     .to(welcomeTextRef.current, 0.5, { opacity: 0, ease: ease.ease10 })
  //     .to(whitePage.current, 0.83, { x: 1800, y: -88, rotation: 6, ease: ease.ease12 }, 0)
  //     .to(progressLineRef.current, 0.63, { scaleY: 1, ease: ease.ease12 }, 0)
  //     .to(greenPage.current, 0.83, { height: '100%', ease: ease.ease13 }, 0)
  //     .to(greenPage.current, 0.63, { width: '100%', left: 0, ease: ease.ease13 }, 0)
  //     .to(purplePage.current, 0.83, { x: -1800, rotation: 6, ease: ease.ease12 }, 0)
  //     .to(downloadRef.current, 0.2, { y: 0, ease: ease.ease12 }, 0.52)
  //     .set(containerRef.current, { zIndex: -1 }, 0.63)
  //     .addLabel('end');
  // }, [downloadRef]);

  // useEffect(() => {
  //   setTimelineIn(buildInTimeline());
  //   setTimelineScroll(buildScrollTimeline());
  // }, [buildInTimeline, buildScrollTimeline]);

  //useGsapMotion({ ref: containerRef, animateIn, animateInit });
  //const motionState = useCSSMotion(containerRef);
  useSectionTimeSpent(name, containerRef);

  // useEffect(() => {
  //   const windowInnerHeight = window.innerHeight;
  //   const viewPortMax = windowInnerHeight * 2.5;
  //   function scrollAnimation() {
  //     if (!isAnimateInDone.current && timelineIn) {
  //       timelineIn.seek('end');
  //       isAnimateInDone.current = true;
  //     }
  //     if (basic.getScrollTop() <= viewPortMax && timelineScroll) {
  //       const time = basic.mapRange(window.scrollY, 0, viewPortMax, 0, 1.2);
  //       timelineScroll.seek(time);
  //     }
  //   }
  //   if (isLarge) {
  //     window.addEventListener('scroll', scrollAnimation);
  //     TweenMax.set(containerRef.current, { zIndex: 1 });
  //   } else {
  //     timelineScroll && timelineScroll.seek(0);
  //     window.removeEventListener('scroll', scrollAnimation);
  //     TweenMax.set(greenPage.current, { clearProps: 'left, width, height' });
  //   }
  //   if (timelineIn && timelineScroll && basic.getScrollTop() <= windowInnerHeight) {
  //     track('SectionView', { section: name });
  //   }
  //   return () => {
  //     window.removeEventListener('scroll', scrollAnimation);
  //   };
  // }, [isLarge, timelineIn, timelineScroll, name]);

  return (
    <header id={id} className={classnames('Splash')} ref={containerRef}>
      <div className="welcome" ref={welcomeTextRef}>
        <span>{backgroundText}</span>
      </div>

      <div className="fan-block purple-block" ref={purplePage}>
        <div className="bottom-part" />
      </div>
      <div className="fan-block green-block" ref={greenPage}>
        <div className="bottom-part" />
      </div>
      <div className="fan-block main-block" ref={whitePage}>
        <div className="bottom-part" />
        <div className="text-container" ref={textContainerRef}>
          <span className="welcome-text">{welcome}</span>
          {/*<Logo />*/}
          <h1>{pageName}</h1>
        </div>

        <div className="scroll-container">
          <div className="vertical-line" ref={scrollIndicatorRef}>
            <div className="progress" ref={progressLineRef} />
          </div>

          <p className="instruction" ref={scrollTextRef}>
            {scrollToRead}
          </p>
        </div>
      </div>
    </header>
  );
}

Splash.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    backgroundText: PropTypes.string,
    welcome: PropTypes.string,
    pageName: PropTypes.string,
    scrollToRead: PropTypes.string,
  }).isRequired,
  downloadRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
});

Splash.defaultProps = {};

export default memo(Splash);
