import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';
import { TweenMax } from 'gsap/all';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as TriangleArrow } from '../../assets/svgs/triangle-arrow.svg';

import './MobileNav.scss';

import { hashManager, basic, ease, getDangerousUnsanitizedHash } from '../../util';

function MobileNav({ links }) {
  const progressBarThrottle = 25;
  const progressBarRef = useRef(null);
  const trackingLimits = useRef(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [activeSection, setActiveSection] = useState(`${links[0].stringIndex} ${links[0].title}`);

  const [handleProgressBar] = useDebouncedCallback(
    useCallback(() => {
      const scrollTop = basic.getScrollTop();

      if (scrollTop < trackingLimits.current.top || scrollTop > trackingLimits.current.bottom) {
        // Note: Restart the line when going over the top and bottom limits
        TweenMax.to(progressBarRef.current, progressBarThrottle / 100, {
          scaleX: 0,
          ease: ease.easeOutCubic,
        });
        if (getDangerousUnsanitizedHash()) {
          hashManager.reset();
        }
      } else {
        trackingLimits.current.bounds.forEach((el) => {
          if (basic.isInBetween(scrollTop, el.top, el.bottom)) {
            setActiveSection(el.title);
            const ratio = (scrollTop - el.top) / el.height;

            if (getDangerousUnsanitizedHash() !== el.id) hashManager.changeByScroll(el.id);
            TweenMax.to(progressBarRef.current, progressBarThrottle / 100, {
              scaleX: ratio.toFixed(2),
              ease: ease.easeOutCubic,
            });
          }
        });
      }
    }, []),
    progressBarThrottle,
    { maxWait: progressBarThrottle },
  );

  const toggleOffDropdown = useCallback(() => {
    toggleDropdown && setToggleDropdown(false);
  }, [toggleDropdown]);

  function getBoundaries(elements) {
    const boundaries = elements.map((el) => {
      const offsetTopElement = el.offsetTop;
      const heightElement = el.getBoundingClientRect().height;

      return {
        top: offsetTopElement,
        height: heightElement,
        bottom: offsetTopElement + heightElement,
        id: el.id,
        title: el.dataset.title,
      };
    });

    const lastElement = elements[elements.length - 1];
    return {
      top: elements[0].offsetTop,
      bottom: lastElement.offsetTop + lastElement.getBoundingClientRect().height,
      bounds: boundaries,
    };
  }

  useEffect(() => {
    const unSanitizedHash = getDangerousUnsanitizedHash();
    if (unSanitizedHash) {
      const result = links.find((link) => link.id === unSanitizedHash.replace('#', ''));
      if (result) {
        setActiveSection(`${result.stringIndex} ${result.title}`);
        hashManager.changeOnLoad(result.id);
      }
    }
  }, [links]);

  useEffect(() => {
    const deepLinkedElements = links.map((link) => document.getElementById(link.id));
    const calculatedBoundaries = getBoundaries(deepLinkedElements);
    trackingLimits.current = calculatedBoundaries;

    window.addEventListener('scroll', toggleOffDropdown);
    window.addEventListener('scroll', handleProgressBar);

    return () => {
      window.removeEventListener('scroll', toggleOffDropdown);
      window.removeEventListener('scroll', handleProgressBar);
    };
  }, [handleProgressBar, links, toggleOffDropdown]);

  return (
    <div className="MobileNav">
      <div className="bar">
        <ul className="chapters">
          <li className="active-element" onClick={() => setToggleDropdown(!toggleDropdown)}>
            <span className="active-title">{activeSection}</span>
            <TriangleArrow className="triangle" />
            <div className="progress-bar" ref={progressBarRef} />
          </li>
          <div className={classnames('dropdown', { isOpen: toggleDropdown })}>
            {links.map((link) => (
              <li key={link.id}>
                <a href={`#${link.id}`} onClick={(e) => hashManager.changeByClick(e, link.id)}>
                  {link.stringIndex} {link.title}
                </a>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
}

MobileNav.propTypes = checkExtraProps({
  links: PropTypes.arrayOf(
    PropTypes.shape({
      stringIndex: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
});

MobileNav.defaultProps = {};

export default memo(MobileNav);
