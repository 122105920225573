import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import { sanitizer } from '../../../util/index';

function footnotes({ data: { items } }) {
  return (
    <ul className="footnotes">
      {items?.map(({ html }, index) => (
        <li key={index} dangerouslySetInnerHTML={{ __html: sanitizer(html, true, {}) }} />
      ))}
    </ul>
  );
}

footnotes.propTypes = checkExtraProps({
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        html: PropTypes.string,
      }),
    ).isRequired,
    text: PropTypes.string,
    link: PropTypes.string,
    html: PropTypes.string,
  }).isRequired,
});

export default memo(footnotes);
