import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import { sanitizer } from '../../../util';

function html({ data: { html } }) {
  if (html) {
    return <div dangerouslySetInnerHTML={{ __html: sanitizer(html, true, {}) }} />;
  } else {
    return null;
  }
}

html.propTypes = checkExtraProps({
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        html: PropTypes.string,
      }),
    ),
    text: PropTypes.string,
    link: PropTypes.string,
    html: PropTypes.string.isRequired,
  }).isRequired,
});

export default memo(html);
