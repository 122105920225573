import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import checkExtraProps from '@jam3/react-check-extra-props';

import './Lexicon.scss';
import { customPropTypes, useCSSMotion, useSectionTimeSpent, sanitizer } from '../../util';

import CollapsibleGrid from '../CollapsibleGrid/CollapsibleGrid';

function Lexicon({ data }) {
  const { title, description, items, showMoreText, id, name, note } = data;
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);
  useSectionTimeSpent(name, containerRef);

  return (
    <div className={classnames('Lexicon', motionState)} id={id} ref={containerRef}>
      <h4 className="subtitle">{title}</h4>
      <h3 className="description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
      <div className="note" dangerouslySetInnerHTML={{ __html: sanitizer(note) }} />
      <CollapsibleGrid
        name={name}
        theme="lexicon"
        items={items}
        itemsToShow={8}
        showMoreText={showMoreText}
        motionState={motionState}
      />
    </div>
  );
}

Lexicon.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    title: PropTypes.string,
    description: PropTypes.string,
    items: CollapsibleGrid.type.propTypes.items,
    showMoreText: PropTypes.string,
  }).isRequired,
});

Lexicon.defaultProps = {};

export default memo(Lexicon);
