import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './WhitepaperWrapper.scss';

function WhitepaperWrapper({ children, smallMargin, whiteRef, isDeepDive }) {
  return (
    <section className="WhitepaperWrapper">
      <div className="white-background" ref={whiteRef} />
      <div className={classnames('container', { 'small-margin': smallMargin, deepDive: isDeepDive })}>{children}</div>
    </section>
  );
}

WhitepaperWrapper.propTypes = checkExtraProps({
  whiteRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  smallMargin: PropTypes.bool,
  isDeepDive: PropTypes.bool,
});

WhitepaperWrapper.defaultProps = {
  smallMargin: false,
  isDeepDive: false,
};

export default memo(WhitepaperWrapper);
